import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { motion } from 'framer-motion';
import './AboutSection.css';
import {Link} from "react-router-dom";

const AboutSection: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        const templateParams = {
            from_name: name,
            from_email: email,
            message: message,
        };

        emailjs.send('service_7w8g1be', 'template_3vfxoi8', templateParams, 'dhzWZk3CKDvYwBikR')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setSuccessMessage('Ihre Nachricht wurde erfolgreich gesendet!');
                setName('');
                setEmail('');
                setMessage('');
            })
            .catch((error) => {
                console.log('FAILED...', error);
                setSuccessMessage('Es gab einen Fehler beim Senden der Nachricht.');
            });
    };

    return (
        <motion.footer
            id="about"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="about-section"
        >
            <div className="about-container">
                <div className="about-content">
                    <h2 className="about-title">Über uns</h2>
                    <p className="about-text">
                        Willkommen auf unserem Minecraft-Server! Unsere Mission ist es, die beste Spielerfahrung
                        zu bieten, indem wir spannende Updates und Events organisieren. Unser Team besteht aus
                        leidenschaftlichen Spielern, die das Spiel lieben und es zu einem sicheren und
                        unterhaltsamen Ort für alle machen wollen.
                    </p>
                    <div className="about-links">
                        <a href="https://discord.gg/4CW5kNPsmY" target="_blank" rel="noopener noreferrer" className="about-link">Discord</a>
                        <a href="https://minecraft-server.eu/" target="_blank" rel="noopener noreferrer" className="about-link">Minecraft Server Forum</a>
                    </div>
                </div>
                <div className="contact-form">
                    <h3 className="contact-title">Kontakt</h3>
                    <form onSubmit={handleSubmit} className="form">
                        <div className="form-group">
                            <label htmlFor="name" className="form-label">Name:</label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                className="form-input"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email" className="form-label">Email:</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="form-input"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message" className="form-label">Nachricht:</label>
                            <textarea
                                id="message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                                className="form-input"
                            />
                        </div>
                        <button type="submit" className="form-button">Absenden</button>
                        {successMessage && <p className="success-message">{successMessage}</p>}
                    </form>
                </div>
            </div>
            <p className="footer-text">
                &copy; 2024 Minecraft Server. Alle Rechte vorbehalten.
                <Link to="/impressum" className="footer-link">Impressum</Link>
            </p>
        </motion.footer>
    );
};

export default AboutSection;
