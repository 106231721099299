import React from 'react';
import { motion } from 'framer-motion';
import './RoadmapSection.css';

const updates = [
    {
        title: "Reise & PvP Update",
        date: "Q3 2024",
        features: [
            "Reiseplugin für erweiterte Spielerinteraktionen",
            "Städte können alle 5 Level ein neues Gebäude von öffentlichem Interesse hinzufügen (z.B. Windmühle, Brauerei, Burg, Rathaus, Lager)",
            "CombatLog Plugin zur besseren Übersicht über Kämpfe",
            "Neue PvP Modi: Grün, Gelb, Rot zur Differenzierung der Kampfstile"
        ],
        color: "#f5c07a",
        secondaryColor: "#f7b278",
        image: "images/trireme.png"
    },
    {
        title: "Städte & Nationsupdate",
        date: "Q4 2024",
        features: [
            "Gebäude werden bewertet und Scores zusammengerechnet, um Upgrades zu ermöglichen",
            "Einführung von Nationen"
        ],
        color: "#b08757",
        secondaryColor: "#d9a470",
        image: "images/banner.png"
    },
    {
        title: "Dungeonupdate",
        date: "Q1 2025",
        features: [
            "Einführung von Dungeons für spannende Erkundungen",
            "Neue Quests für herausfordernde Missionen",
            "Interessante einzigartige Items"
        ],
        color: "#a57c5b",
        secondaryColor: "#c89674",
        image: "images/rucksack.png"
    },
    {
        title: "Landwirtschaftsupdate",
        date: "Q2 2025",
        features: [
            "Einführung von Reis und anderen Crops",
            "Einführung von Getränken"
        ],
        color: "#805a3b",
        secondaryColor: "#9b7352",
        image: "images/farmer.png"
    },
    {
        title: "Kriegs & Professionupdate",
        date: "Q3 2025",
        features: [
            "-",
        ],
        color: "#805a3b",
        secondaryColor: "#9b7352",
        image: "images/background.png"
    }
];


const RoadmapSection: React.FC = () => {
    return (
        <motion.section
            id="roadmap"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="roadmap-section py-20 bg-white text-center"
        >
            <h2 className="text-3xl md:text-4xl font-bold mb-8">Roadmap</h2>
            <div className="timeline">
                {updates.map((update, index) => (
                    <div key={index} className={`timeline-item ${index === updates.length - 1 ? 'fade' : ''}`}>
                        <div className="timeline-left">
                            <p className="timeline-date" style={{ color: update.secondaryColor }}>{update.date}</p>
                        </div>
                        <div className="timeline-center">
                            <div className="timeline-icon" style={{ backgroundColor: update.color }}></div>
                            <div className="timeline-line" style={{ backgroundColor: update.color }}></div>
                        </div>
                        <div className="timeline-content">
                            <img src={update.image} alt={update.title} className="timeline-image" />
                            <div>
                                <h3 className="timeline-title" style={{ color: update.color }}>{update.title}</h3>
                                <ul className="timeline-features">
                                    {update.features.map((feature, i) => (
                                        <li key={i} className="timeline-feature-item">{feature}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </motion.section>
    );
};

export default RoadmapSection;
