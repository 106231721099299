import React from 'react';
import { motion } from 'framer-motion';
import './HomeSection.css';

const HomeSection: React.FC = () => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText('mcterranova.de');
        alert('IP-Adresse kopiert: mcterranova.de');
    };

    return (
        <motion.section
            id="home"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="home-section"
        >
            <h1 className="home-title">Willkommen zu Terranova!</h1>
            <p className="home-subtitle">Tritt unserem Abenteuer bei und baue monumentale Städte!</p>
            <div className="cta-buttons">
                <a href="https://discord.gg/4CW5kNPsmY" target="_blank" rel="noopener noreferrer" className="cta-button discord-button">Join Discord</a>
            </div>
            <button onClick={copyToClipboard} className="server-ip-button">
                Server IP: mcterranova.de
            </button>
        </motion.section>
    );
};

export default HomeSection;
