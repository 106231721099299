import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomeSection from './components/HomeSection';
import AboutSection from './components/AboutSection';
import RoadmapSection from './components/RoadmapSection';
import Impressum from './pages/Impressum';
import './App.css';

const App: React.FC = () => {
    return (
        <Router>
            <div className="font-sans text-gray-900">
                <nav className="navbar fixed top-0 w-full bg-gray-800 text-white py-4 z-50">
                    <ul className="flex justify-center space-x-4">
                        <li><a href="#home" className="cursor-pointer hover:text-green-500">Home</a></li>
                        <li><a href="#roadmap" className="cursor-pointer hover:text-green-500">Roadmap</a></li>
                        <li><a href="#about" className="cursor-pointer hover:text-green-500">Kontakt</a></li>
                        <li><a href="https://map.mcterranova.de" target="_blank" rel="noopener noreferrer" className="cursor-pointer hover:text-green-500">Map</a></li>
                    </ul>
                </nav>
                <Routes>
                    <Route path="/" element={
                        <>
                            <HomeSection />
                            <RoadmapSection />
                            <AboutSection />
                        </>
                    } />
                    <Route path="/impressum" element={<Impressum />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
